import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import imagen from '../assets/images/galeria-de-imagenes (1).png';
import Certificado from '../assets/images/agreement.png'
import documentos from '../assets/images/Documentos.png'




export const URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.BASE_URL}/api/`
    : "https://report-intenalco-back.firmadoc.co/api/";

export const tableUrl = {
  user: URL + "user",
  document: URL + "document",
  consumption: URL + "product",
  transactions: URL + "transactions",
};


const handleDownloadDocument = async (data) => {
  const IdNumber = data.usu_docume;

  try {
    const response = await fetch(
      URL + `certificadoEnroll/${IdNumber}`
    );

    const data = await response.json();

    if(!data.pdf) {
      alert('Certificado de enrolamiento no disponible!');
      return;
    }

    const binaryString = window.atob(data.pdf);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download",'CertificadoEnroll-'+ IdNumber);
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    console.log(error);
  }
};


export const columns = {
  es: {
    user: [
      {
        name: "Nombre",
        selector: (row) => row.usu_nombre + " " + row.usu_apelli,
        sortable: true,
      },
      {
        name: "Documento",
        selector: (row) => row.usu_tipo_documento + " " + row.usu_docume,
        sortable: true,
      },
      {
        name: "Correo",
        selector: (row) => row.usu_email,
        sortable: true,
      },
      {
        name: "Celular",
        selector: (row) => row.usu_celula,
      },
      {
        name: "Rol",
        selector: (row) =>
          row.nombre_rol === "Funcionario Emcali" ? "user" : row.nombre_rol,
        sortable: true,
      },
      {
        name: "Certificado",
        cell: (data) => (
          <h2 onClick={()=>handleDownloadDocument(data)} style={{ color: "#FF0000", cursor: "pointer" }} title="Descargar">
            <img src={Certificado}/>
          </h2>
        ),
      },
    ],
    document: [
      {
        name: "Nombre",
        selector: (row) => row.document_name,
        sortable: true,
      },
      {
        name: "Estado",
        selector: (row) => row.state,
        sortable: true,
      },
      {
        name: "Fecha creacion",
        selector: (row) => row.send_date + " " + row.send_hour,
        sortable: true,
      },
      {
        name: "Fecha Firma",
        selector: (row) =>
          row.sign_date && row.sign_hour
            ? row.sign_date + " " + row.sign_hour
            : "- -",
      }
    ],
    consumption: [
      {
        name: "Firmas compradas",
        selector: (row) => row.bought_firms,
        sortable: true,
      },
      {
        name: "Firmas Usadas",
        selector: (row) => row.used_firms,
        sortable: true,
      },
    ],
    transactions: [
      {
        name: "Record",
        selector: (row) => row.Record,
        sortable: true,
      },
      {
        name: "Fecha registro",
        selector: (row) => row.StartingDate,
        sortable: true,
      },
      {
        name: "Cedula",
        selector: (row) => row.IdNumber,
        sortable: true,
      },
      {
        name: "Nombre",
        selector: (row) =>
          row.FirstName + " " + row.FirstSurname + " " + row.SecondSurname,
        sortable: true,
      },
      {
        name: "Lugar de Nacimiento",
        selector: (row) => row.PlaceBirth,
        sortable: true,
      },
      {
        name: "Tipo transaccion",
        selector: (row) => row.TransactionTypeName,
        sortable: true,
      },
    ],
  },
  
  en: {
    user: [
      {
        name: "Name",
        selector: (row) => row.usu_nombre + " " + row.usu_apelli,
        sortable: true,
      },
      {
        name: "Document",
        selector: (row) => row.usu_tipo_documento + " " + row.usu_docume,
        sortable: true,
      },
      {
        name: "Mail",
        selector: (row) => row.usu_email,
        sortable: true,
      },
      {
        name: "CellPhone",
        selector: (row) => row.usu_celula,
      },
      {
        name: "Rol",
        selector: (row) =>
          row.nombre_rol === "Funcionario Emcali" ? "user" : row.nombre_rol,
        sortable: true,
      },
      {
        name: "Certificate",
        cell: () => (
          <h2 style={{ color: "#FF0000", cursor: "pointer" }} title="Descargar">
            <img src={Certificado}/>
          </h2>
        ),
      },
    ],
    document: [
      {
        name: "Name",
        selector: (row) => row.document_name,
        sortable: true,
      },
      {
        name: "State",
        selector: (row) => row.state,
        sortable: true,
      },
      {
        name: "Creation date",
        selector: (row) => row.send_date + " " + row.send_hour,
        sortable: true,
      },
      {
        name: "Signature date",
        selector: (row) =>
          row.sign_date && row.sign_hour
            ? row.sign_date + " " + row.sign_hour
            : "- -",
      }
    ],
    consumption: [
      {
        name: "SignaturesBought ",
        selector: (row) => row.bought_firms,
        sortable: true,
      },
      {
        name: "SignaturesUsed",
        selector: (row) => row.used_firms,
        sortable: true,
      },
    ],
    transactions: [
      {
        name: "Record",
        selector: (row) => row.Record,
        sortable: true,
      },
      {
        name: "Registrationdate",
        selector: (row) => row.StartingDate,
        sortable: true,
      },
      {
        name: "Identificationnumber",
        selector: (row) => row.IdNumber,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) =>
          row.FirstName + " " + row.FirstSurname + " " + row.SecondSurname,
        sortable: true,
      },
      {
        name: "Placeofbirth",
        selector: (row) => row.PlaceBirth,
        sortable: true,
      },
      {
        name: "Transactiontype",
        selector: (row) => row.TransactionTypeName,
        sortable: true,
      },
    ],
  }
};

export const columnsModal = {
es:{
  completed: [
    {
      name: "Nombre",
      selector: (row) => row.document_name,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Fecha Firma",
      selector: (row) => row.sign_date + " " + row.sign_hour,
      sortable: true,
    },
    {
      name: "Destinatarios",
      selector: (row) => row.destinataries,
      sortable: true,
    },
  ],

  pending: [
    {
      name: "Nombre",
      selector: (row) => row.document_name,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Fecha Envio",
      selector: (row) => row.send_date + " " + row.send_hour,
      sortable: true,
    },
    {
      name: "Destinatarios",
      selector: (row) => row.destinataries,
      sortable: true,
    },
  ],
},
en:{
  completed: [
    {
      name: "Name",
      selector: (row) => row.document_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Signature date",
      selector: (row) => row.sign_date + " " + row.sign_hour,
      sortable: true,
    },
    {
      name: "Recipients",
      selector: (row) => row.destinataries,
      sortable: true,
    },
  ],

  pending: [
    {
      name: "Name",
      selector: (row) => row.document_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Signature date",
      selector: (row) => row.send_date + " " + row.send_hour,
      sortable: true,
    },
    {
      name: "Recipients",
      selector: (row) => row.destinataries,
      sortable: true,
    },
  ],

},
};


export const ConcumptionColumnsFunction = (callback, lang) => [
  {
    name: lang == "es" ? "Nombre" : "Name",
    selector: (row) => row.user_name,
    sortable: true,
  },
  {
    name: lang == "es" ? "Cantidad" : "Amount",
    selector: (row) => row.signed_documents_count,
    sortable: true,
  },
  {
    name: lang == "es" ? "Documentos" : "Documents",
    cell: (row) => (
      <h2
        style={{ color: "#00000", cursor: "pointer" }}
        title="Mostrar Documentos"
        onClick={()=>callback(row)}
      >
        <img src={documentos}/>
      </h2>
    ),
  },
];

export const TransactionColumnsFunction = (callback, lang) => [
  {
    name: lang == "es" ? "Record" : "Record",
    selector: (row) => row.Record,
    sortable: true,
  },
  {
    name: lang == "es" ? "Fecha de Registro" : "Registration Date",
    selector: (row) => row.StartingDate,
    sortable: true,
  },
  {
    name: lang == "es" ? "Cedula" : "ID Number",
    selector: (row) => row.IdNumber,
    sortable: true,
    
  },
  {
    name: lang == "es" ? "Nombre" : "Name",
    selector: (row) => `${row.FirstName} ${row.SecondName} ${row.FirstSurname} ${row.SecondSurname}`,
    sortable: true,
  },
  {
    name: lang == "es" ? "Lugar de Nacimiento" : "Place of birth",
    selector: (row) => row.PlaceBirth,
    sortable: true,
  },
  {
    name: lang == "es" ? "Tipo de Transaccion" : "Transaction Type",
    selector: (row) => row.TransactionTypeName,
    sortable: true,
    style: {justifyContent: "center"},
  },
  {
    name: lang == "es" ? "Imagenes" : "Images",
    cell: (row) => (
      <h2
        style={{ color: "#00000", cursor: "pointer" }}
        title="Mostrar Imágenes"
        onClick={()=>callback(row)}
      >
        <img src={imagen} alt="Icono" style={{paddingLeft:"57%"}} /> 
      </h2>
    ),
  },
];

export const TABS = {
  USER: "user",
  DOCUMENT: "document",
  CONSUMPTION: "consumption",
  TRANSACTION: "transactions",
  REPORTS: "reports"
};


// {
//   name: "Certificado",
//   cell: () => (
//     <h2 style={{ color: "#FF0000", cursor: "pointer" }} title="Descargar">
//       <BsFillFileEarmarkPdfFill />
//     </h2>
//   ),
// },